import { $ajax } from '../api'

// 首页概览
export default class Home {

    constructor() { }

    static home = {
        tenantHome: '/api/tenant/home.action',
    }

    //租户首页
    static tenantHome = (params) => {
        return $ajax.post(`${this.home.tenantHome}`, params).then(res => res.data);
    }
    
}