import { $ajax } from '../api'
import { $dxios } from '../api'

// 生产管理

export default class Factory {

    constructor() { }

    static factory = {
        addBatch: '/api/factory/addBatch.action',         //添加生产批次
        deleteBatch: '/api/factory/deleteBatch.action',   //删除生产批次
        updateBatch: '/api/factory/updateBatch.action',   //更新生产批次
        batchList: '/api/factory/batchList.action',       //分页批次列表
        allBatch: '/api/factory/allBatch.action',         //全部生产批次
        testGateway: '/api/factory/testGateway.action',   //测试网关联网
        gatewayList: '/api/factory/gatewayList.action',   //测试网关列表
    }

    //网关列表查询
    static gatewayList = (params) => {
        return $ajax.post(`${this.factory.gatewayList}`, params).then(res => res.data);
    }

    //添加生产批次
    static addBatch = (params) => {
        return $ajax.post(`${this.factory.addBatch}`, params).then(res => res.data);
    }

     //删除生产批次
     static deleteBatch = (params) => {
        return $ajax.post(`${this.factory.deleteBatch}`, params).then(res => res.data);
    }

    //更新生产批次
    static updateBatch = (params) => {
        return $ajax.post(`${this.factory.updateBatch}`, params).then(res => res.data);
    }

     //分页批次列表
     static batchList = (params) => {
        return $ajax.post(`${this.factory.batchList}`, params).then(res => res.data);
    }

     //全部生产批次
     static allBatch = (params) => {
        return $ajax.post(`${this.factory.allBatch}`, params).then(res => res.data);
    }


    //测试网关联网
    static testGateway = (params) => {
        return $ajax.post(`${this.factory.testGateway}`, params).then(res => res.data);
    }

    
}